import React, { useState } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { emailStorage } from "../utils/storage"
import { focusDOM } from "../utils/dom"
import registerEndpoint from "../api/register"

import SEO from "./seo"
import "./register.scss"

const Register = ({ token, lang, user, locales }) => {
  const l = locales[lang] || locales.en

  const invitedCompanion =
    user.c && !user.c.i ? { name: user.c.n, email: user.c.e } : null
  const allowCompanion = !!(user.c && user.c.i && !invitedCompanion)

  const namePlaceholder = l[`placeholder_name_${user.g}`]
  const namePlaceholderOther =
    l[`placeholder_name_${user.g === "m" ? "f" : "m"}`]

  const [isAttending, setAttending] = useState(user.a !== 0)
  const [name, setName] = useState(`${user.n || ""}`)
  const [email, setEmail] = useState(`${user.e || ""}`)
  const [createUser, setCreateUser] = useState(true)
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [hasCompanion, setCompanion] = useState(allowCompanion)
  const [companionName, setCompanionName] = useState(
    `${(user.c && user.c.n) || ""}`
  )
  const [companionEmail, setCompanionEmail] = useState(
    `${(user.c && user.c.e) || ""}`
  )
  const [hasChildren, setChildren] = useState(!!user.ch)
  const [childrenCount, setChildrenCount] = useState(
    (user.ch && user.ch.length) || 1
  )
  const [childName1, setChildName1] = useState(
    `${(user.ch && user.ch[0]) || ""}`
  )
  const [childName2, setChildName2] = useState(
    `${(user.ch && user.ch[1]) || ""}`
  )
  const [isLoading, setLoading] = useState(false)
  const [isSubmittedOnce, setSubmittedOnce] = useState(false)
  const [serverResponse, setServerResponse] = useState("")
  const relevantHasCompanion = isAttending && allowCompanion && hasCompanion
  const relevantChildrenCount = isAttending && hasChildren ? childrenCount : 0
  const relevantHasChildren = isAttending && hasChildren
  const relevantChildren =
    relevantChildrenCount === 0
      ? []
      : relevantChildrenCount === 1
      ? [childName1.trim()]
      : [childName1.trim(), childName2.trim()]
  const validation = []

  if (!name.trim()) {
    validation.push(`missing_name`)
  }

  if (!email.trim()) {
    validation.push(`missing_email`)
  }

  if (!/@/.test(email)) {
    validation.push(`invalid_email`)
  }

  if (createUser) {
    if (!password1 && !password2) {
      validation.push(`missing_passwords`)
    } else if (!password1) {
      validation.push(`missing_password1`)
    } else if (password1.length < 4) {
      validation.push(`short_password`)
    } else if (!password2) {
      validation.push(`missing_password2`)
    } else if (password1 !== password2) {
      validation.push(`invalid_password`)
    }
  }

  if (relevantHasCompanion) {
    if (!companionName.trim()) {
      validation.push(`missing_companion_name`)
    }

    if (!companionEmail.trim()) {
      validation.push(`missing_companion_email`)
    }

    if (!/@/.test(companionEmail)) {
      validation.push(`invalid_companion_email`)
    }
  }

  if (relevantHasChildren) {
    if (relevantChildrenCount === 1 && !childName1.trim()) {
      validation.push(`missing_child_name`)
    }

    if (
      relevantChildrenCount > 1 &&
      (!childName1.trim() || !childName2.trim())
    ) {
      validation.push(`missing_child_names`)
    }
  }

  const errors = validation.slice(0)
  if (!isLoading && serverResponse) {
    errors.unshift(l.errors[serverResponse] ? serverResponse : `server_error`)
  }

  return (
    <React.Fragment>
      <SEO title={l.title} lang={lang} />
      <form
        className="register--container"
        name="register"
        onSubmit={async e => {
          e.preventDefault()
          setSubmittedOnce(true)
          if (isLoading) return

          if (validation.length > 0) return focusDOM("register-errors")

          emailStorage.set(email)
          setLoading(true)
          const response = await registerEndpoint({
            token,
            attending: isAttending,
            name: name.trim(),
            email: email.trim(),
            password: createUser ? password1 : "",
            companion: relevantHasCompanion
              ? {
                  name: companionName.trim(),
                  email: companionEmail.trim(),
                }
              : null,
            children: relevantChildren,
          })
          setLoading(false)
          const error = (response || {}).error
          if (error) {
            setServerResponse(error)
            focusDOM("register-errors")
            return
          }

          const thanksType = isAttending
            ? createUser
              ? `attending`
              : `attending-no-user`
            : createUser
            ? `user`
            : `reply`
          navigate(`/register/${lang}/thanks-${thanksType}/`, {
            replace: true,
          })
          focusDOM("register-thanks")
        }}
      >
        <h1 className="fg-black-5">
          {l.heading1}
          <br />
          <span>{l.heading2}</span>
        </h1>
        <section
          className="register--box bg-white-3-off ba-white-4"
          style={{ paddingTop: `40px`, paddingBottom: `40px` }}
        >
          <label className="register--radio-label register--radio-label-big fg-black-5">
            <input
              id="attending-yes"
              name="attending"
              type="radio"
              className="fg-black-3"
              value="true"
              disabled={isLoading}
              checked={isAttending}
              onChange={e => setAttending(e.target.value === "true")}
            />
            {` ${l.attending_yes}`}
          </label>
          <label className="register--radio-label register--radio-label-big fg-black-5">
            <input
              id="attending-yes"
              name="attending"
              type="radio"
              className="fg-black-3"
              disabled={isLoading}
              value="false"
              checked={!isAttending}
              onChange={e => setAttending(e.target.value === "true")}
            />{" "}
            {` ${l.attending_no}`}
          </label>
        </section>
        <section>
          <p className="fg-black-5" style={{ paddingTop: `40px` }}>
            <label className="register--input-label" htmlFor="name">
              {l.name}
            </label>
            <input
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              className="fg-black-3 bb-black-3"
              placeholder={namePlaceholder}
              required
              disabled={isLoading}
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </p>
          <p className="fg-black-5">
            <label className="register--input-label" htmlFor="email">
              {l.email}
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              className="fg-black-3 bb-black-3"
              placeholder={`${(
                name || namePlaceholder
              ).toLowerCase()}@example.com`}
              required
              disabled={isLoading}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </p>
          <p className="fg-black-5" style={{ marginTop: `40px` }}>
            <label
              className="register--checkbox-label fg-black-5 bb-black-3"
              aria-describedby="create_user_description"
            >
              <input
                id="create_user"
                name="create_user"
                type="checkbox"
                disabled={isLoading}
                checked={createUser}
                onChange={e => setCreateUser(e.target.checked)}
              />
              {` ${l.create_user}`}
            </label>
          </p>
          <p className={`fg-black-${createUser ? 5 : 9}`}>
            <label className="register--input-label" htmlFor="password">
              {l.password}
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="new-password"
              className={`fg-black-${createUser ? 3 : 8} bb-black-${
                createUser ? 3 : 8
              }`}
              placeholder={l.password_placeholder}
              required={createUser}
              disabled={isLoading || !createUser}
              value={password1}
              onChange={e => setPassword1(e.target.value)}
            />
          </p>
          <p className={`fg-black-${createUser ? 5 : 9}`}>
            <label className="register--input-label" htmlFor="password2">
              {l.password_repeat}
            </label>
            <input
              id="password2"
              name="password2"
              type="password"
              autoComplete="new-password"
              className={`fg-black-${createUser ? 3 : 8} bb-black-${
                createUser ? 3 : 8
              }`}
              placeholder={l.password_placeholder}
              required={createUser}
              disabled={isLoading || !createUser}
              value={password2}
              onChange={e => setPassword2(e.target.value)}
            />
          </p>
          <p
            className="register--create-user-description fg-black-6"
            id="create_user_description"
          >
            {l.create_user_text}
          </p>
        </section>
        <section
          className="register--box bg-white-3-off ba-white-4"
          style={{
            marginTop: `40px`,
            marginBottom: `40px`,
            paddingBottom: `10px`,
            display:
              isAttending && (allowCompanion || invitedCompanion)
                ? `block`
                : `none`,
          }}
        >
          <h2 className="register--box-heading fg-black-3">
            {l.heading_companion}
          </h2>
          <p
            className="register--companion-invited fg-black-5"
            style={{ display: invitedCompanion ? `block` : `none` }}
          >
            <em>{invitedCompanion && invitedCompanion.name}</em>
            {l.companion_invited1}
            <em>{invitedCompanion && invitedCompanion.email}</em>
            {l.companion_invited2}
          </p>
          <p
            className="fg-black-3"
            style={{ display: invitedCompanion ? `none` : `block` }}
          >
            <label className="register--checkbox-label fg-black-3 bb-black-3">
              <input
                id="companion"
                name="companion"
                type="checkbox"
                disabled={isLoading}
                checked={hasCompanion}
                onChange={e => setCompanion(e.target.checked)}
              />{" "}
              {l.companion}
            </label>
          </p>
          <p
            className={`fg-black-${hasCompanion ? 5 : 9}`}
            style={{ display: invitedCompanion ? `none` : `block` }}
          >
            <label className="register--input-label" htmlFor="companion_name">
              {l.companion_name}
            </label>
            <input
              id="companion_name"
              name="companion_name"
              type="text"
              autoComplete="name"
              className={`fg-black-${hasCompanion ? 3 : 8} bb-black-${
                hasCompanion ? 3 : 8
              }`}
              placeholder={namePlaceholderOther}
              required={allowCompanion && hasCompanion && !invitedCompanion}
              disabled={isLoading || !hasCompanion}
              value={companionName}
              onChange={e => setCompanionName(e.target.value)}
            />
          </p>
          <p
            className={`fg-black-${hasCompanion ? 5 : 9}`}
            style={{ display: invitedCompanion ? `none` : `block` }}
          >
            <label className="register--input-label" htmlFor="companion_email">
              {l.companion_email}
            </label>
            <input
              id="companion_email"
              name="companion_email"
              type="email"
              autoComplete="email"
              className={`fg-black-${hasCompanion ? 3 : 8} bb-black-${
                hasCompanion ? 3 : 8
              }`}
              placeholder={`${(
                companionName || namePlaceholderOther
              ).toLowerCase()}@example.com`}
              required={allowCompanion && hasCompanion && !invitedCompanion}
              disabled={isLoading || !hasCompanion}
              value={companionEmail}
              onChange={e => setCompanionEmail(e.target.value)}
            />
          </p>
        </section>
        <section
          className="register--box bg-white-3-off ba-white-4"
          style={{
            marginTop: `40px`,
            marginBottom: `40px`,
            paddingBottom: `10px`,
            display: isAttending ? `block` : `none`,
          }}
        >
          <h2 className="register--box-heading fg-black-3">
            {l.heading_children}
          </h2>
          <p className="fg-black-3">
            <label className="register--checkbox-label fg-black-3 bb-black-3">
              <input
                id="children"
                name="children"
                type="checkbox"
                disabled={isLoading}
                checked={hasChildren}
                onChange={e => setChildren(e.target.checked)}
              />{" "}
              {l.children}
            </label>
          </p>
          <p className={`fg-black-${hasChildren ? 5 : 9}`}>
            <label className="register--input-label" htmlFor="children_count">
              {l.children_count}
            </label>
            <select
              id="children_count"
              name="children_count"
              className={`fg-black-${hasChildren ? 3 : 8} bb-black-${
                hasChildren ? 3 : 8
              }`}
              required={hasChildren}
              disabled={!hasChildren}
              value={`${childrenCount}`}
              onChange={e => setChildrenCount(+e.target.value)}
            >
              <option value="1">{l.children_count_1}</option>
              <option value="2">{l.children_count_2}</option>
            </select>
          </p>
          <p
            className="fg-black-5"
            style={{
              display: relevantChildrenCount > 0 ? `block` : `none`,
            }}
          >
            <label className="register--input-label" htmlFor="children_name_1">
              {relevantChildrenCount > 1 ? l.children_name_1 : l.children_name}
            </label>
            <input
              id="children_name_1"
              name="children_name_1"
              type="text"
              autoComplete="name"
              className="fg-black-3 bb-black-3"
              placeholder={l.children_name_placeholder}
              required={relevantChildrenCount > 0}
              disabled={isLoading || !hasChildren}
              value={childName1}
              onChange={e => setChildName1(e.target.value)}
            />
          </p>
          <p
            className="fg-black-5"
            style={{
              display: relevantChildrenCount > 1 ? `block` : `none`,
            }}
          >
            <label className="register--input-label" htmlFor="children_name_2">
              {l.children_name_2}
            </label>
            <input
              id="children_name_2"
              name="children_name_2"
              type="text"
              autoComplete="name"
              className="fg-black-3 bb-black-3"
              placeholder={l.children_name_placeholder}
              required={relevantChildrenCount > 1}
              disabled={isLoading || !hasChildren}
              value={childName2}
              onChange={e => setChildName2(e.target.value)}
            />
          </p>
        </section>
        <p className="register--submit-area">
          <input
            className="register--submit-button bg-white-0 fg-black-3 ba-black-3"
            type="submit"
            disabled={isLoading}
            value={
              isAttending
                ? l.register
                : createUser
                ? l.register_user
                : l.register_none
            }
          />
        </p>
        <section
          className="register--submit-errors fg-red-0"
          role="alert"
          id="register-errors"
          tabIndex="-1"
        >
          {isSubmittedOnce && errors.length > 0 ? (
            <ul>
              {errors.map(error_id => (
                <li key={error_id}>{l.errors[error_id]}</li>
              ))}
            </ul>
          ) : null}
        </section>
      </form>
    </React.Fragment>
  )
}

Register.propTypes = {
  lang: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  locales: PropTypes.object.isRequired,
}

export default Register
