import React from "react"
import { Router } from "@reach/router"
import { decode } from "jsonwebtoken"

import log from "../utils/log"
import Register from "../components/register"
import RegisterThanks from "../components/register-thanks"
import Layout from "../components/layout"
import NotFoundPage from "./404"

const EmptyPage = () => <div />

const languages = ["en", "de", "da"]
const thankTypes = ["attending", "attending-no-user", "user", "reply"]

const test_user =
  process.env.NODE_ENV === "production"
    ? {
        n: "Test Name",
        e: "test@test.com",
        g: "m",
        c: {
          i: 1,
          n: "Other Test Name",
          e: "other@test.com",
        },
        ch: ["Test Child 1", "Test Child 2"],
      }
    : {
        // a: 0, // not attending
        n: "Bjarke", // name
        e: "bwp@bwp.dk", // email
        g: "f", // gender
        // companion
        c: {
          // i: 1, // true = allowed, false = invited
          n: "Lavinia", // name
          e: "laviniaknop@gmail.com", // email
        },
        // ch: ["Child 1", "Child 2"], // children names
      }

const parseToken = token => {
  if (token === "test" && test_user) return test_user

  try {
    return decode(token)
  } catch (error) {
    log(`[pages:register:parseToken] - Failed to parse token - error: ${error}`)
    return null
  }
}

const RegisterComponent = props => {
  const { lang, token } = props

  if (!languages.includes(lang) || !token) {
    return <NotFoundPage />
  }

  if (token.startsWith("thanks-")) {
    const type = token.substring(7)

    if (!thankTypes.includes(type)) {
      return <NotFoundPage />
    }

    return (
      <Layout hideNav={true}>
        <RegisterThanks
          {...props}
          type={type.replace(/-no-user/, "")}
          createUser={["attending", "user"].includes(type)}
          locales={{
            en: {
              attending: {
                title: `Thank you for your registration`,
              },
              user: {
                title: `Thank you for creating a website user`,
              },
              reply: {
                title: `Thank you for your response`,
              },
              phone: `+49 176 43376311`,
              text_confirmation: `You'll receive a confirmation email shortly.`,
              text_questions1: `If you have any questions or issues with the registration, please do not hesitate to contact us by e-mail `,
              text_questions2: ` or phone `,
              text_questions3: ` (Lavinia).`,
              text_login: `You may now continue to the login page:`,
              link_login: `Login`,
            },
            de: {
              attending: {
                title: `Vielen Dank für deine Anmeldung`,
              },
              user: {
                title: `Vielen Dank für die Erstellung deines Webseite-Nutzers`,
              },
              reply: {
                title: `Vielen Dank für deine Antwort`,
              },
              phone: `0176 43376311`,
              text_confirmation: `Du wirst gleich eine Bestätigung per E-Mail bekommen (auf Englisch).`,
              text_questions1: `Wenn du Probleme oder Fragen zu der Anmeldung hast, schreib eine E-Mail an `,
              text_questions2: ` oder ruf uns an unter `,
              text_questions3: ` (Lavinia).`,
              text_login: `Du kannst jetzt zu der Login-Seite vortfahren:`,
              link_login: `Login`,
            },
            da: {
              attending: {
                title: `Mange tak for din tilmelding`,
              },
              user: {
                title: `Mange tak for at oprette en bruger på hjemmesiden`,
              },
              reply: {
                title: `Mange tak for dit svar`,
              },
              phone: `+49 176 43376311`,
              text_confirmation: `Du vil modtage en bekræftigelse per e-mail snarest (på engelsk).`,
              text_questions1: `Hvis du har spørgsmål eller problemer med tilmeldingen, er du velkommen til at skrive til os per e-mail `,
              text_questions2: ` eller ringe til os på `,
              text_questions3: ` (Lavinia).`,
              text_login: `Du kan nu gå videre til login-siden:`,
              link_login: `Login`,
            },
          }}
        />
      </Layout>
    )
  }

  const user = parseToken(token)
  if (!user || !user.n || !user.e || !user.g) {
    return <NotFoundPage />
  }

  return (
    <Layout hideNav={true}>
      <Register
        {...props}
        user={user}
        locales={{
          en: {
            title: `Registration`,
            placeholder_name_m: `John`,
            placeholder_name_f: `Jane`,
            heading1: `Registration for our wedding`,
            heading2: `September 7, 2019`,
            heading_companion: `Companion`,
            heading_children: `Children`,
            attending_yes: `Yes, I'm attending`,
            attending_no: `No, I won't attend`,
            name: `Your name`,
            email: `Your email`,
            create_user: `Create a user for the website`,
            create_user_text: `On the website you'll get valuable information before and after our wedding. Creating a user is optional, but we would recommend it. You'll be able to login with your email and password.`,
            password: `Password`,
            password_repeat: `Repeat password`,
            password_placeholder: `your secret here`,
            companion_invited1: ` has been invited via the email `,
            companion_invited2: `. We kindly ask that they register personally.`,
            companion: `I would like to bring a companion`,
            companion_name: `Name of companion`,
            companion_email: `Email of companion`,
            children: `I would like to bring my children`,
            children_count: `Number of children`,
            children_count_1: `1 child`,
            children_count_2: `2 children`,
            children_name: `Name of child`,
            children_name_1: `Name of 1st child`,
            children_name_2: `Name of 2nd child`,
            children_name_placeholder: `Child`,
            register: `Register`,
            register_user: `Create user`,
            register_none: `Save response`,
            errors: {
              missing_name: `Please fill out your name.`,
              missing_email: `Please fill out your email.`,
              invalid_email: `Please check your email.`,
              missing_passwords: `Please enter a password to create a user on the website.`,
              missing_password1: `Please fill out the password two times.`,
              short_password: `Please choose a longer password.`,
              missing_password2: `Please repeat the password.`,
              invalid_password: `The two passwords don't match, please check them.`,
              missing_companion_name: `Please fill out the name of your companion.`,
              missing_companion_email: `Please fill out the email of your companion.`,
              invalid_companion_email: `Please check the email of your companion.`,
              missing_child_name: `Please fill out the name of your child.`,
              missing_child_names: `Please fill out the names of your children.`,
              already_registered: `You're already registered, try to log in.`,
              server_error: `An error occurred while processing your registration, please try again later.`,
            },
          },
          de: {
            title: `Anmeldung`,
            placeholder_name_m: `Max`,
            placeholder_name_f: `Erika`,
            heading1: `Anmeldung für unsere Hochzeit`,
            heading2: `7. September 2019`,
            heading_companion: `Begleitperson`,
            heading_children: `Kinder`,
            attending_yes: `Ja, ich nehme teil`,
            attending_no: `Nein, ich nehme nicht teil`,
            name: `Dein Name`,
            email: `Deine E-Mail`,
            create_user: `Einen Nutzer für die Website erstellen`,
            create_user_text: `Sobald du einen Nutzer erstellt hast, kannst du auf alle Inhalte zugreifen, die wir vor und nach der Hochzeit teilen. Log dich einfach mit deiner E-Mail-Adresse und einem Passwort deiner Wahl ein.`,
            password: `Passwort`,
            password_repeat: `Passwort wiederholen`,
            password_placeholder: `dein Geheimnis hier`,
            companion_invited1: ` wurde über die E-Mail-Adresse `,
            companion_invited2: ` eingeladen. Wir bitten um eine individuelle Anmeldung.`,
            companion: `Ich werde eine Begleitperson mitnehmen`,
            companion_name: `Name der Begleitperson`,
            companion_email: `E-Mail der Begleitperson`,
            children: `Ich werde meine Kinder mitnehmen`,
            children_count: `Anzahl Kinder`,
            children_count_1: `1 Kind`,
            children_count_2: `2 Kinder`,
            children_name: `Name des Kindes`,
            children_name_1: `Name des 1. Kindes`,
            children_name_2: `Name des 2. Kindes`,
            children_name_placeholder: `Kind`,
            register: `Anmelden`,
            register_user: `Nutzer erstellen`,
            register_none: `Antwort speichern`,
            errors: {
              missing_name: `Bitte gib deinen Namen an.`,
              missing_email: `Bitte gib deine E-Mail-Adresse an.`,
              invalid_email: `Bitte überprüfe deine E-Mail-Adresse.`,
              missing_passwords: `Bitte wähle ein Passwort um ein Nutzer auf die Webseite zu erstellen.`,
              missing_password1: `Bitte das Passwort zweimal eingeben.`,
              short_password: `Bitte wähle ein längeres Passwort.`,
              missing_password2: `Bitte das Passwort wiederholen.`,
              invalid_password: `Die beiden Passwörter stimmen nicht überein.`,
              missing_companion_name: `Bitte gib den Namen der Begleitperson an.`,
              missing_companion_email: `Bitte gib die E-Mail-Adresse der Begleitperson an.`,
              invalid_companion_email: `Bitte überprüfe die E-Mail-Adresse der Begleitperson.`,
              missing_child_name: `Bitte gib den Namen deines Kindes an.`,
              missing_child_names: `Bitte gib den Namen deiner Kinder an.`,
              already_registered: `Du bist schon angemeldet, bitte einloggen.`,
              server_error: `Bei der Bearbeitung deiner Anmeldung ist ein Fehler aufgetreten, bitte später erneut versuchen.`,
            },
          },
          da: {
            title: `Tilmelding`,
            placeholder_name_m: `Anders`,
            placeholder_name_f: `Anna`,
            heading1: `Tilmelding til vores bryllup`,
            heading2: `7. september 2019`,
            heading_companion: `Ledsager`,
            heading_children: `Børn`,
            attending_yes: `Ja, jeg deltager`,
            attending_no: `Nej, jeg deltager ikke`,
            name: `Dit navn`,
            email: `Din e-mail`,
            create_user: `Opret en bruger til hjemmesiden`,
            create_user_text: `På hjemmesiden finder du værdifuld information før og efter vores bryllup. Det er frivilligt at oprette en bruger, men vi anbefaler det. Du kan logge ind med din e-mail og adgangskode.`,
            password: `Adgangskode`,
            password_repeat: `Gentag adgangskode`,
            password_placeholder: `din hemmelighed her`,
            companion_invited1: ` er inviteret via e-mailen `,
            companion_invited2: `. Vi beder om at de registrerer sig personligt.`,
            companion: `Jeg vil gerne tage en ledsager med`,
            companion_name: `Ledsagers navn`,
            companion_email: `Ledsagers e-mail`,
            children: `Jeg vil gerne tage mine børn med`,
            children_count: `Antal børn`,
            children_count_1: `1 barn`,
            children_count_2: `2 børn`,
            children_name: `Navn på barn`,
            children_name_1: `Navn på 1. barn`,
            children_name_2: `Navn på 2. barn`,
            children_name_placeholder: `Barn`,
            register: `Tilmeld`,
            register_user: `Opret bruger`,
            register_none: `Gem svar`,
            errors: {
              missing_name: `Udfyld venligst dit navn`,
              missing_email: `Udfyld venligst din e-mail-adresse`,
              invalid_email: `Check venligst din e-mail-adresse`,
              missing_passwords: `Vælg venligst en adgangskode for at oprette en bruger på hjemmesiden`,
              missing_password1: `Udfyld venligst adgangskoden to gange`,
              short_password: `Vælg venligst en længere adgangskode`,
              missing_password2: `Gentag venligst adgangskoden`,
              invalid_password: `De to adgangskoder stemmer ikke overens, check dem venligst`,
              missing_companion_name: `Udfyld venligst din ledsagers navn`,
              missing_companion_email: `Udfyld venligst din ledsagers e-mail-adresse`,
              invalid_companion_email: `Check venligst din ledsagers e-mail-adresse`,
              missing_child_name: `Udfyld venligst navnet på dit barn`,
              missing_child_names: `Udfyld venligst navnet på dine børn`,
              already_registered: `Du er allerede tilmeldt, prøv at logge ind.`,
              server_error: `Der opstod en fejl under behandlingen af din tilmelding, prøv igen senere.`,
            },
          },
        }}
      />
    </Layout>
  )
}

const RegisterPage = () => (
  <Router>
    <RegisterComponent path="/register/:lang/:token/" />
    <EmptyPage default />
  </Router>
)

export default RegisterPage
