import axios from "./axios"
import { getTime, slowDown } from "./util"

export default slowDown(async data => {
  try {
    const res = await axios.post(`register?t=${getTime()}`, data)
    return res.data
  } catch (error) {
    const res = ((error || {}).response || {}).data || {}
    return res.error ? res : { error: `server_error` }
  }
})
