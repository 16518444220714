import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import SEO from "./seo"
import "./register-thanks.scss"

const RegisterThanks = ({ lang, type, createUser, locales }) => {
  const l = locales[lang] || locales.en
  const l_reply = l[type] || l.reply

  return (
    <React.Fragment>
      <SEO title={l_reply.title} lang={lang} />
      <section
        id="register-thanks"
        className="register-thanks--container"
        tabIndex="-1"
      >
        <h1 className="fg-black-5">{l_reply.title}</h1>
        <p className="fg-black-3">{l.text_confirmation}</p>
        <p className="fg-black-3">
          {l.text_questions1}
          <a href="mailto:laviniaknop@gmail.com" className="fg-black-5">
            laviniaknop@gmail.com
          </a>
          {l.text_questions2}
          <a href="tel:+491738975730" className="fg-black-5">
            {l.phone}
          </a>
          {l.text_questions3}
        </p>
        <p
          className="fg-black-3"
          style={{ display: createUser ? `block` : `none` }}
        >
          {l.text_login}
        </p>
        <p
          className="fg-black-3"
          style={{ display: createUser ? `block` : `none` }}
        >
          <Link className="fg-black-5 register-thanks--link-login" to="/login/">
            {l.link_login}
          </Link>
        </p>
      </section>
    </React.Fragment>
  )
}

RegisterThanks.propTypes = {
  lang: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  createUser: PropTypes.bool.isRequired,
  locales: PropTypes.object.isRequired,
}

export default RegisterThanks
